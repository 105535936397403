
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import KTTopbar from "@/layout/header/Topbar.vue";
import { useRouter, useRoute } from "vue-router";

import mAxiosApi from "@/api";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {
    KTTopbar,
  },
  emits: ["reloadRouter"],

  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const breadcrumbs = store.getters.pageBreadcrumbPath;

    // localStorage.setItem('storedData', this.input)

    onMounted(async () => {
      let storedConst = localStorage.getItem('storedConst');
      if (!storedConst) {
        let myList = await mAxiosApi.prototype.getAxios("/getConstLogo");
        localStorage.setItem('storedConst', JSON.stringify(myList.results))
      } 

      
      // const storedConst = JSON.parse(localStorage.getItem('storedConst'));
    });

    const reloadRouter = () => {
      emit("reloadRouter", 1);
    }

    const backRoute = () => {
      const mPath = route.path as any;
      if (mPath.indexOf("/commande/") === 0) {
        if (store.getters.getUrlHisto === "") {
          router.push("/stillboard/commandes")
        }else{
          router.push(store.getters.getUrlHisto);
        }
      }
    };

    const displayBack = () => {
      const mPath = route.path as any;
      if (mPath.indexOf("/commande/") === 0) return true;
      return false;
    };

    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      breadcrumbs,
      backRoute,
      displayBack,
      reloadRouter,
    };
  },
});

